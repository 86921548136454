import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {

	data: function () {
		return {
			units: [],
			imagesForModal: [],
			showUnits: false
		};
	},
	beforeMount: function () {
		let unitgroup;
		let el = document.querySelector("[data-unitgroup]");
		if (el) {
			unitgroup = parseInt(el.getAttribute('data-unitgroup'), 10);
		} else {
			//console.log(' is not found..................');
		}

		//console.log('unitgroup::::', unitgroup);

		//unitgroup = 13711;

		//console.log('unitgroup', unitgroup);
		if (!isNaN(unitgroup)) {
			this.showUnits = true;
			//console.log('Unit Group ist eine Zahl');
		} else {
			this.showUnits = false;
			//console.log('Unit Group ist keine Zahl!');
		}

		if (this.showUnits) {

			var results = this.$el.getElementsByClassName('unit-list-section');
			if (results.length) {
				this.resultFields = findResultFields(results[0].innerHTML, this.$el);
			}

			var req = {
				fields: this.resultFields,
				filter: {
					unitgroupid: unitgroup
				},
				sorting: 'random',
				max: 6
			};

			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;
				});
		}


	},

	mounted: function () {
		this.$nextTick(function () {
			//window.addEventListener('resize', this.handleResize);
		});
	},

	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();


	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {

		handleResize: function () {
			//this.applySlider();

		},
		showImagesModal: function (images, uniqueId = '') {
			if (images && images.length > 1) {
				this.imagesForModal = images;
				//$('#unitImages').modal('show');
				this.showCustomModal('unitImages' + uniqueId);

			} else {
				this.imagesForModal = [];
			}
		},
		showCustomModal: function (id) {
			let wh = window.innerHeight;

			// Get the modal
			const modal = document.getElementById(id);

			if (modal) {
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];
				var body = document.body;

				if (dialog && content) {
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";

					modal.style.display = "block";
					body.classList.add('modal-open');


					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
							body.classList.remove('modal-open');
						}
					};
				}
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			var body = document.body;
			if (modal) {
				modal.style.display = "none";
				body.classList.remove('modal-open');
			}

		},
		getUnitSearchTarget: function (id) {
			return 'unit_' + id;
		},

		applySlider: function () {

			var windowWidth = window.innerWidth;
			if (windowWidth < 768) {
				$(".owl-carousel.blogentry").owlCarousel({
					loop: false,
					//margin: VOFFICE.settings.olwCarouselMargin,
					smartSpeed: 1000,
					autoplay: false,
					nav: false,
					dots: true,
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: 2
						},
						996: {
							items: 3
						},
						1200: {
							items: 4

						}
					}
				});
			} else {
				$('.owl-carousel.unitsofthedayslide').owlCarousel('destroy');
			}
		}
	},
	filters: {
		formatfeedback: function (value) {
			if (!value) return '';
			if (Number.isInteger(value)) return value.toFixed();
			return (value.toFixed(1).replace('.', ','));
		}
	}
};